import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getAllBlogs } from "../features/ads/adsSlice";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const AdComponent = () => {
    const dispatch = useDispatch();
    const { blog } = useSelector((state) => state.blog);
    useEffect(() => {
        dispatch(getAllBlogs());
    }, [dispatch]);
console.log("blog",blog);

    const filteredBlogs = blog.filter(item => item.category === "Special Offers")
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const isOffline = !navigator.onLine;
    if (isOffline) {
        return (
            <div className="hidden items-center justify-center">
                <div className="text-center">
                    <h2 className="hidden text-2xl font-bold mb-2">Oops!</h2>
                    <p className="hidden">You are currently offline. Please check your internet connection.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="w-full bg-gray-100 sm:mb-9">
            <h2 style={{ backgroundColor: '#ff0000' }} className="text-3xl font-bold text-center mb-4 p-3 text-white">Special Offers</h2>
            <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={16}
                slidesPerView={2}
                autoplay={{ delay: 5000 }}
                navigation
                pagination={{ clickable: true }}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 12,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 16,
                    },
                }}
            >
                {filteredBlogs.map((ad, index) => (
                    <SwiperSlide key={index}>
                        <div className="p-4 bg-white shadow-lg rounded-lg text-center">
                            <Link to={`/${ad.subcategory}`}>
                                <img
                                    src={ad.images[0]?.secure_url}
                                    alt={ad.title}
                                    className="w-full h-48 object-cover rounded-lg mb-2"
                                />
                            </Link>
                            <h3 className="text-lg font-semibold">{ad.title}</h3>
                            <div
                                className="bg-gray-100 shadow-md "
                            >
                                <p
                                    dangerouslySetInnerHTML={{ __html: ad.description }}

                                ></p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default AdComponent;
