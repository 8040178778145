// ProductDetails.js
import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { useSwipeable } from "react-swipeable";
import { getAProduct, getAllProducts } from "../features/products/productSlice";
import Footer from "./Footer";
import axios from "axios";
import Modal from "react-modal";
import "../asset/productdetal.css"; // Ensure this is imported only once
import { addToCart } from "../redux/actions/cartAction";
import SwiperCore from "swiper";
import HeroSlider from "./HomeAds";
import AdComponent1 from "./AdComponent1";
import { AiOutlineZoomIn, AiOutlineClose } from "react-icons/ai";
import "swiper/css/bundle"; // Ensure this is imported only once
import RelatedProducts from "./RelatedProducts";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";
import "../index.css";
import "../App.css";

SwiperCore.use([Navigation, Pagination, Autoplay]);

Modal.setAppElement("#root");

// ... rest of the component code

const ProductDetails = () => {
  const { id } = useParams();
  //const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize the navigate hook

  const [color, setColor] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const [isFilled, setIsFilled] = useState(false);
  const [showToast, setShowToast] = useState(false); // State for showing toast
  const [toastMessage, setToastMessage] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [products, setProducts] = useState(null);
  const [colorDetails, setColorDetails] = useState([]);

  const [selectedColorIndex, setSelectedColorIndex] = useState(null);

  const {
    singleproduct,
    isLoading,
    isError,
    message: productMessage,
  } = useSelector((state) => state?.product);

  useEffect(() => {
    if (singleproduct?.colors) {
      // Flatten the images array from all colors on initial load
      const images = singleproduct.colors.flatMap(
        (color) => color.images || []
      );
      setAllImages(images);
    }
  }, [singleproduct]);
  const handleImageClick = (event) => {
    event.stopPropagation();
    setIsZoomed(!isZoomed);
  };
  const openGallery = () => {
    setIsGalleryOpen(true);
    setIsZoomed(false);
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
    setIsZoomed(false);
  };
  const handleSwipe = (direction) => {
    if (direction === "LEFT") {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % allImages.length);
    } else if (direction === "RIGHT") {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + allImages.length) % allImages.length
      );
    }
  };
  const { product } = useSelector((state) => state.product);
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("LEFT"),
    onSwipedRight: () => handleSwipe("RIGHT"),
  });
  const currentImage = allImages[currentIndex];
  const wishlistState = useSelector((state) => state?.auth?.wishlist?.wishlist);
  //const colors = useSelector((state) => state?.auth?.colors || []);
  const [selectedColor, setSelectedColor] = useState(null);

  const [selectedSize, setSelectedSize] = useState(null);

  const getTokenFromLocalStorage = useMemo(() => {
    return localStorage.getItem("customer")
      ? JSON.parse(localStorage.getItem("customer"))
      : null;
  }, []);

  const config2 = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${
          getTokenFromLocalStorage ? getTokenFromLocalStorage.token : ""
        }`,
        Accept: "application/json",
      },
    }),
    [getTokenFromLocalStorage]
  );
  useEffect(() => {
    // Initialize product states based on Redux wishlist
    const initialStates = {};
    wishlistState?.forEach((item) => {
      initialStates[item._id] = true;
    });
  }, [wishlistState]);
  // Inside ProductDetails component

  useEffect(() => {
    dispatch(getAProduct(id))
      .unwrap()
      .catch((error) => {
        console.error("Failed to fetch product:", error);
        // Optionally set an error state to display a message to the user
      });
    dispatch(getAllProducts())
      .unwrap()
      .catch((error) => {
        console.error("Failed to fetch products:", error);
        // Optionally set an error state to display a message to the user
      });
  }, [dispatch, id, config2]);

  useEffect(() => {
    if (
      singleproduct &&
      singleproduct.color &&
      singleproduct.color.length > 0
    ) {
      fetchProductColors(singleproduct.color);
    }
  }, [singleproduct]);

  const fetchProductColors = async (colorIds) => {
    try {
      const colorResponse = await axios.post(
        "http://localhost:5000/api/color/byIds",
        { ids: colorIds },
        config2
      );
      setColorDetails(colorResponse.data);
    } catch (error) {
      console.error("Error fetching color details:", error);
    }
  };
  useEffect(() => {
    if (singleproduct) {
      setProducts(singleproduct);
    }
  }, [singleproduct]);

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 3000); // Close toast after 3 seconds

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showToast]);

  // When user clicks on a color option
  const handleColorClick = (colorIndex) => {
    setSelectedColorIndex(colorIndex);
    const selectedColor = singleproduct.colors[colorIndex];

    if (selectedColor) {
      const images = selectedColor.images || [];
      setAllImages(images);
      setCurrentIndex(0); // Reset to first image of the selected color
      setSelectedColor(selectedColor);
    } else {
      setToastMessage("Selected color is invalid or not available.");
      setShowToast(true);
    }
  };

  useEffect(() => {
    if (wishlistState) {
      setIsFilled(wishlistState.some((item) => item._id === id));
    }
  }, [wishlistState, id]);

  const addToCartHandler = (productId) => {
    // Check if the size is "Free Size"
    const isFreeSize = singleproduct.sizes && singleproduct.sizes.length === 0; // Free Size case

    if (!selectedColor) {
      toast.error("Please select a color.");
      return;
    }

    // Skip size check if it's a "Free Size" product
    if (!selectedSize && !isFreeSize) {
      toast.error("Please select a size.");
      return;
    }

    // Create an object containing productId and selected attributes
    const productWithSelection = {
      productId, // the product's id
      selectedColor, // the selected color
      selectedSize: isFreeSize ? "Free Size" : selectedSize, // Assign "Free Size" if applicable
    };

    // Dispatch the action with the new object
    dispatch(addToCart({ ...productWithSelection, navigate }));
  };

  const handleShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: singleproduct.title,
          text: `Check out this product: ${singleproduct.title}`,
          url: window.location.href,
        })
        .then(() => console.log("Share successful"))
        .catch((error) => console.error("Error sharing", error));
    } else {
      // Fallback: Copy the URL to clipboard if Web Share API is not supported
      navigator.clipboard.writeText(window.location.href).then(
        function () {
          toast.success("Product URL copied to clipboard!");
        },
        function (err) {
          toast.error("Failed to copy URL to clipboard.");
          console.error("Could not copy text: ", err);
        }
      );
    }
  };

  if (isLoading || !singleproduct.colors) {
    return (
      <div className="flex space-x-2 justify-center items-center  h-auto mt-5">
        <span className="sr-only">Loading...</span>
        <div className="h-8 w-8 bg-red-600 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
        <div className="h-8 w-8 bg-red-600 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
        <div className="h-8 w-8 bg-red-600 rounded-full animate-bounce"></div>
      </div>
    );
  }

  if (!singleproduct || singleproduct.colors.length === 0) {
    return <div>No product or colors available.</div>;
  }

  // Function to handle clicking on a size
  const handleSizeClick = (size) => {
    setSelectedSize(size); // Set the clicked size as the selected size
  };

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };
  const isOffline = !navigator.onLine;

  // Offline Check
  if (isOffline) {
    return (
      <div className="hidden items-center justify-center">
        <div className="text-center">
          <h2 className="hidden text-2xl font-bold mb-2">Oops!</h2>
          <p className="hidden">
            You are currently offline. Please check your internet connection.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <main className="main__content_wrapper">
        <section className="mb-5">
          <HeroSlider />
        </section>

        <div className="pdetail">
          <section className="sectionone">
            <div className="col">
              <div className="product__details--media">
                <div className="product__media--preview grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8">
                  <div
                    className="flex flex-col product__media--preview__items justify-center items-center"
                    {...swipeHandlers}
                    onClick={handleImageClick}
                    style={{ position: "relative" }}
                  >
                    {currentImage ? (
                      <div
                        key={currentImage._id}
                        className={`product__media--preview__items--link cursor-pointer ${
                          isZoomed ? "zoomed" : ""
                        }`}
                      >
                        <img
                          className={`transition-transform duration-300 ease-in-out ${
                            isZoomed ? "scale-150" : "scale-100"
                          } object-contain`}
                          src={currentImage.secure_url}
                          alt="product-media-img"
                        />
                      </div>
                    ) : (
                      <p>No image available</p>
                    )}
                    <h1 className="mt-4 text-lg lg:text-xl">
                      {currentIndex + 1}/{allImages.length}
                    </h1>
                    <button
                      className="absolute top-2 right-2 text-white bg-gray-700 p-2 rounded-full"
                      onClick={openGallery}
                    >
                      <AiOutlineZoomIn size={35} />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Gallery Modal */}
            {isGalleryOpen && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                <div className="relative max-w-5xl w-full mx-4">
                  <button
                    className="absolute top-4 z-50 right-4 bg-gray-800 text-white rounded-md"
                    onClick={closeGallery}
                  >
                    <AiOutlineClose size={40} />
                  </button>
                  <div
                    className="flex justify-center items-center"
                    onClick={handleImageClick}
                    {...swipeHandlers}
                  >
                    <img
                      className={`transition-transform duration-300 ease-in-out ${
                        isZoomed ? "scale-150" : "scale-100"
                      } max-h-[80vh] object-contain`}
                      src={currentImage.secure_url}
                      alt="gallery-img"
                    />
                  </div>
                  <h1 className="mt-4 text-lg lg:text-xl text-white text-center">
                    {currentIndex + 1}/{allImages.length}
                  </h1>
                </div>
              </div>
            )}
          </section>
          <section className="sectiontwo placeholder:product__details--section section--padding pb-6">
            <div className="container">
              <div className="row row-cols-1 lg:row-cols-2">
                <div className="col">
                  <div className="product__details--info">
                    <form action="">
                      <h2 className="text-black font-bold text-2xl lg:text-4xl">
                        {singleproduct.title}
                      </h2>
                      <div className="product__details--info__price mb-10 lg:mb-16">
                        <span className="current__price font-bold text-lg lg:text-2xl">
                          {singleproduct.price}.00 ETB
                        </span>
                        <span className="price__divided" />
                        <span className="product__items--content__title old__price text-red-600">
                          {singleproduct.oldPrice}.00 ETB
                        </span>
                        {/* Share Icon */}
                        <span
                          className="share-icon pl-20 text-black hover:text-blue-500 transition-transform duration-300 ease-in-out hover:scale-110"
                          onClick={handleShareClick}
                        >
                       
                        </span>
                      </div>
                      <div className="lg:text-xl">
                        <div className="text-2xl">
                          {isExpanded ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: singleproduct.description,
                              }}
                            ></p>
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: `${singleproduct?.description?.substring(
                                  0,
                                  50
                                )}...`,
                              }}
                            ></p>
                          )}
                        </div>
                        <div
                          onClick={toggleDescription}
                          className="text-blue-500 mb-3 cursor-pointer lg:text-lg"
                        >
                          {isExpanded ? "See Less" : "See More"}
                        </div>
                      </div>

                      <div className="product__variant--list mb-6 lg:mb-12">
                        <fieldset className="variant__input--fieldset weight">
                          <legend className="product__variant--title mb-4 text-lg lg:text-2xl">
                            Brand: {singleproduct.brand}
                          </legend>
                        </fieldset>
                      </div>

                      <div className="flex product__variant lg:mb-8">
                        <span> Colors :</span>
                        <div className="flex space-x-2 mt-4 lg:space-x-4">
                          {singleproduct?.colors?.map((color, colorIndex) => (
                            <div
                              key={color._id}
                              className={`rounded-full product__media--nav__items w-[60px] h-[60px] lg:w-[80px] lg:h-[80px] cursor-pointer ${
                                selectedColor === color
                                  ? "border-2 border-red-600"
                                  : ""
                              }`}
                              onClick={() => handleColorClick(colorIndex)}
                            >
                              {color.images[0] ? (
                                <img
                                  className="product__media--nav__items--img rounded-full"
                                  src={color.images[0].secure_url}
                                  alt={`Color ${color.color.title}`}
                                />
                              ) : (
                                <p>this product has fixed color</p>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="flex flex-wrap mt-6 lg:mt-10 mb-4">
                        <h3>Size:</h3>
                        <div className="flex space-x-2 m-2 lg:space-x-4 lg:m-4">
                          {singleproduct &&
                          singleproduct.sizes &&
                          singleproduct.sizes.length > 0 ? (
                            singleproduct.sizes.map((size, index) => (
                              <h1
                                key={index}
                                onClick={() => handleSizeClick(size)} // Handle the click event
                                className={`text font-medium text-gray-600 border border-gray-800 rounded-md p-2 lg:p-4 cursor-pointer ${
                                  selectedSize === size
                                    ? "border-black bg-red-600"
                                    : ""
                                }`} // Change style for selected size
                              >
                                {size}
                              </h1>
                            ))
                          ) : (
                            <h1
                              onClick={() => handleSizeClick("Free Size")} // Handle Free Size click event
                              className={`text font-medium text-gray-600 border border-gray-300 rounded-md p-2 lg:p-4 cursor-pointer ${
                                selectedSize === "Free Size"
                                  ? "border-black bg-red-600"
                                  : ""
                              }`} // Change style for selected Free Size
                            >
                              Free Size
                            </h1>
                          )}
                        </div>
                      </div>
                      <div className="product__variant--list quantity flex items-center mb-10 lg:mb-16">
                        <button
                          className="quickview__cart--btn primary__btn ml-4 lg:ml-8"
                          type="button"
                          onClick={() => addToCartHandler(singleproduct._id)}
                        >
                          Add to Cart
                        </button>
                      </div>

                      {showToast && (
                        <div className="fixed top-8 right-4 bg-gray-100  text-green-600 font-bold p-4 rounded-lg shadow-2xl z-50">
                          {toastMessage}
                        </div>
                      )}

                      <div className="product__variant--list mb-15 lg:mb-24">
                        <Link
                          to="/cart"
                          className="variant__buy--now__btn primary__btn lg:px-8 lg:py-4"
                        >
                          Buy it now
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="mb-10">
          <AdComponent1 />
        </section>
        <section className="product__section product__section--style3 section--padding pt-0">
          <div className="container product3__section--container">
            <div className="section__heading text-center mb-50">
              <h2 className="section__heading--maintitle">Related Products</h2>
            </div>
            <div className="product__section--inner product__swiper--column4__activation swiper">
              <div className="swiper-wrapper">
                <Swiper
                  spaceBetween={50}
                  slidesPerView={4}
                  navigation={{ hidden: true }}
                  loop={true}
                  breakpoints={{
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 2,
                    },
                    // when window width is >= 640px
                    640: {
                      slidesPerView: 2,
                    },
                    // when window width is >= 768px
                    768: {
                      slidesPerView: 2,
                    },
                    // when window width is >= 1024px
                    0: {
                      slidesPerView: 2,
                    },
                    1200: {
                      slidesPerView: 5,
                    },
                  }}
                >
                  {product.map((product) => (
                    <SwiperSlide key={product._id}>
                      <div className="swiper-slide">
                        <div className="product__items ">
                          <div className="product__items--thumbnail">
                            <Link
                              className="product__items--link"
                              to={`/productdetails/${product._id}`}
                            >
                              <img
                                className="product__items--img product__primary--img"
                                src={
                                  product?.colors?.[0]?.images?.[0]?.secure_url
                                }
                                alt="product-img"
                              />
                              <img
                                className="product__items--img product__secondary--img"
                                src={
                                  product?.colors?.[0]?.images?.[1]
                                    ?.secure_url ||
                                  product?.colors?.[0]?.images?.[0]?.secure_url
                                }
                                alt="product-img"
                              />
                            </Link>
                            <div className="product__badge">
                              {product.quantity > 0 ? (
                                <span className="product__badge--items sale">
                                  OnSale
                                </span>
                              ) : (
                                <span className="product__badge--items sale">
                                  StockOut
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="product__items--content">

                            <h3 className="product__items--content__title h4">
                              {product.title}
                            </h3>
                            <div className="product__items--price">
                              <span className="text-md font-bold text-gray-700">
                                {product.price}.00 ETB
                              </span>
                              <span className="price__divided" />
                              <span className="product__items--content__title old__price text-red-600">
                                {product.oldPrice}.00 ETB
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default ProductDetails;
