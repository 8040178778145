import React, { useState } from 'react';  
import axios from 'axios';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import Footer from './Footer';

const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .required('Email is Required')
    .email('Email should be valid'),
});

const otpSchema = yup.object({
  otp: yup
    .string()
    .required('OTP is required')
    .matches(/^\d{6}$/, 'OTP should be a 6-digit number'),
});

const passwordSchema = yup.object({
  newPassword: yup
    .string()
    .required('New password is required')
    .min(6, 'Password should be at least 6 characters long'),
});

const ForgotPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpMode, setOtpMode] = useState(false);
  const navigate = useNavigate();

  // Email Formik
  const emailFormik = useFormik({
    initialValues: { email: '' },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post('https://wibfashionapi.mindhackers.org/api/user/forgot-password', { email: values.email });
        toast.success(response.data.message);
        setEmail(values.email);
        setOtpMode(true);
      } catch (error) {
        toast.error('Error: ' + (error.response?.data?.message || 'Something went wrong'));
      }
    },
  });

  // OTP Formik
  const otpFormik = useFormik({
    initialValues: { otp: '' },
    validationSchema: otpSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post('https://wibfashionapi.mindhackers.org/api/user/verify-otp', {
          email,
          otp: values.otp,
        });
        toast.success('OTP verified successfully! Please enter a new password.');
        setOtpVerified(true);
      } catch (error) {
        toast.error('Error: ' + (error.response?.data?.message || 'Invalid OTP'));
      }
    },
  });

  // New Password Formik
  const passwordFormik = useFormik({
    initialValues: { newPassword: '' },
    validationSchema: passwordSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post('https://wibfashionapi.mindhackers.org/api/user/reset-password', {
          email,
          newPassword: values.newPassword,
        });
        toast.success('Password reset successfully! Redirecting to login...');
        navigate('/login');
      } catch (error) {
        toast.error('Error: ' + (error.response?.data?.message || 'Something went wrong'));
      }
    },
  });

  return (
    <div>
      <main className="main__content_wrapper">
        {/* Start breadcrumb section */}
        <section className="breadcrumb__section breadcrumb__bg">
          <div className="container">
            <div className="row row-cols-1">
              <div className="col">
                <div className="breadcrumb__content text-center">
                  <h1 className="breadcrumb__content--title text-white mb-25">
                    Forgot Password
                  </h1>
                  <ul className="breadcrumb__content--menu d-flex justify-content-center">
                    <li className="breadcrumb__content--menu__items">
                      <Link className="text-white" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb__content--menu__items">
                      <span className="text-white">Forgot Password</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End breadcrumb section */}
        
        {/* Start forgot password section */}
        <div className="login__section section--padding sm:mb-10 sm:-mt-10">
          <div className="container">
            <div className="login__section--inner">
              <div className="row row-cols-md-2 row-cols-1">
                <div className="col">
                  <div className="account__login">
                    <div className="account__login--header mb-25">
                      <h2 className="account__login--header__title h3 mb-10">
                        {otpVerified ? 'Set New Password' : otpMode ? 'Enter OTP' : 'Reset Your Password'}
                      </h2>
                      <p className="account__login--header__desc">
                        {otpVerified
                          ? 'Enter your new password below.'
                          : otpMode
                          ? 'Enter the OTP sent to your email address.'
                          : 'Enter your email address to receive a password reset link.'}
                      </p>
                    </div>
                    <div className="account__login--inner">
                      {!otpMode ? (
                        <form onSubmit={emailFormik.handleSubmit}>
                          <input
                            className="account__login--input"
                            placeholder="Email Address"
                            type="email"
                            name="email"
                            value={emailFormik.values.email}
                            onChange={emailFormik.handleChange}
                            onBlur={emailFormik.handleBlur}
                          />
                          {emailFormik.touched.email && emailFormik.errors.email && (
                            <div className="error text-red-500 text-sm">{emailFormik.errors.email}</div>
                          )}

                          <button className="account__login--btn primary__btn" type="submit">
                            Send Reset Link
                          </button>
                        </form>
                      ) : otpVerified ? (
                        <form onSubmit={passwordFormik.handleSubmit}>
                          <input
                            className="account__login--input"
                            placeholder="New Password"
                            type="password"
                            name="newPassword"
                            value={passwordFormik.values.newPassword}
                            onChange={passwordFormik.handleChange}
                            onBlur={passwordFormik.handleBlur}
                          />
                          {passwordFormik.touched.newPassword && passwordFormik.errors.newPassword && (
                            <div className="error text-red-500 text-sm">{passwordFormik.errors.newPassword}</div>
                          )}

                          <button className="account__login--btn primary__btn" type="submit">
                            Reset Password
                          </button>
                        </form>
                      ) : (
                        <form onSubmit={otpFormik.handleSubmit}>
                          <input
                            className="account__login--input"
                            placeholder="Enter OTP"
                            type="text"
                            name="otp"
                            value={otpFormik.values.otp}
                            onChange={otpFormik.handleChange}
                            onBlur={otpFormik.handleBlur}
                          />
                          {otpFormik.touched.otp && otpFormik.errors.otp && (
                            <div className="error text-red-500 text-sm">{otpFormik.errors.otp}</div>
                          )}

                          <button className="account__login--btn primary__btn" type="submit">
                            Verify OTP
                          </button>
                        </form>
                      )}
                      <p className="account__login--link mt-20">
                        Remember your password? <Link to="/login">Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End forgot password section */}
      </main>
      <Footer />
    </div>
  );
};

export default ForgotPassword;
