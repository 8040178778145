import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

function Htop() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const isDefaultRoute = location.pathname === "/";

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("scrollNavbar");
      if (window.scrollY > 100) {
        navbar.classList.remove("hidden");
      } else {
        navbar.classList.add("hidden");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleBackClick = () => {
    navigate(-1);
  };

  const currentPath = location.pathname;

  return (
    <div id="scrollNavbar" className="fixed w-full top-0 left-0 z-50 bg-gray-100 shadow-lg border-b border-gray-200 md:hidden lg:hidden xl:hidden">
      <div className="flex justify-center">
        <ul className="flex space-x-8 py-4 overflow-x-auto scrollbar-hide w-full px-4">
          {!isDefaultRoute && (
            <li>
              <button
                className="text-gray-700 hover:text-black"
                onClick={handleBackClick}
              >
                <IoIosArrowBack size={35} className="absolute left-0 top-2 -mr-16  shadow-xl text-red-600" />
              </button>
            </li>
          )}
          <li>
            <Link
              to="/shop"
              className={`text-gray-700 hover:text-black ml-6 ${currentPath === "/shop" ? "font-bold text-red-600 border-b-2 border-red-600" : ""
                }`}
              onClick={() => setIsOpen(false)}
            >
              Shop
            </Link>
          </li>
          <li>
            <Link
              to="/women"
              className={`text-gray-700 hover:text-black ${currentPath === "/women" ? "font-bold text-red-600 border-b-2 border-red-600" : ""
                }`}
              onClick={() => setIsOpen(false)}
            >
              Women
            </Link>
          </li>
          <li>
            <Link
              to="/men"
              className={`text-gray-700 hover:text-black ${currentPath === "/men" ? "font-bold text-red-600 border-b-2 border-red-600" : ""
                }`}
              onClick={() => setIsOpen(false)}
            >
              Men
            </Link>
          </li>
          <li>
            <Link
              to="/kid"
              className={`text-gray-700 hover:text-black ${currentPath === "/kid" ? "font-bold text-red-600 border-b-2 border-red-600" : ""
                }`}
              onClick={() => setIsOpen(false)}
            >
              Kids
            </Link>
          </li>
          <li>
            <Link
              to="/couple"
              className={`text-gray-700 hover:text-black ${currentPath === "/couple" ? "font-bold text-red-600 border-b-2 border-red-600" : ""
                }`}
              onClick={() => setIsOpen(false)}
            >
              Couples
            </Link>
          </li>
          <li>
            <Link
              to="/new"
              className={`text-gray-700 hover:text-black ${currentPath === "/new" ? "font-bold text-red-600 border-b-2 border-red-600" : ""
                }`}
              onClick={() => setIsOpen(false)}
            >
              NewIn
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Htop;
