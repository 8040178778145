import React, { useEffect, useState } from "react";
import Footer from "./Footer";
//import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../features/user/userSlice";
import { loginUser } from "../features/user/userSlice";
import { Link, useNavigate } from "react-router-dom";


const signUpSchema = yup.object({
  firstname: yup.string().required("First Name is Required"),
  lastname: yup.string().required("Last Name is Required"),
  username: yup.string().required("Username is Required"),
  email: yup.string().required("Email is Required").email("Email Should be valid"),
  mobile: yup.number().required().positive().integer("Mobile No is Required"),
  password: yup.string().required("Password is Required"),
  confirmPassword: yup.string().required("Confirm Password is Required").oneOf([yup.ref('password'), null], "Passwords must match"),
});

const loginSchema = yup.object({
  email: yup
    .string()
    .required("Email is Required")
    .email("Email Should be valid"),

  password: yup.string().required("Password is Required"),
});

function Login() {
  const RegAuthState = useSelector((state) => state.auth);
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: ""
    },
    validationSchema: signUpSchema,
    onSubmit: (values) => {
      dispatch(registerUser(values));
    },
  });
  const LoginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      dispatch(loginUser(values));
    },
  });

  useEffect(() => {
    if (RegAuthState.isError) {
      const errorMessage = RegAuthState.message && typeof RegAuthState.message === "string"
        ? RegAuthState.message
        : "This email is already registered. Please try another one.";

      setModalMessage(
        errorMessage.includes("User Already Exists")
          ? "This email is already registered. Please try another one."
          : errorMessage
      );
      setModalType("error");
      setModalVisible(true);
    } else if (RegAuthState.isSuccess && RegAuthState.createdUser) {
      setModalMessage("You have been registered! Now you can log in");
      setModalType("success");
      setModalVisible(true);
      // or any other route
    }
  }, [RegAuthState, navigate]);

  useEffect(() => {
    if (authState.user !== null && !authState.isError) {
      setModalMessage("You have logged in! Happy Shopping");
      setModalType("success");
      setModalVisible(true);
      window.location.href = "/";
    }
  }, [authState]);

  const closeModal = () => {
    setModalVisible(false);
    setModalMessage("");
    setModalType("");
  };

  const isOffline = !navigator.onLine;
  if (isOffline) {
    return (
      <div className="hidden items-center justify-center">
        <div className="text-center">
          <h2 className="hidden text-2xl font-bold mb-2">Oops!</h2>
          <p className="hidden">You are currently offline. Please check your internet connection.</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <main className="main__content_wrapper">
        {/* Start breadcrumb section */}
        <section className="breadcrumb__section breadcrumb__bg">
          <div className="container">
            <div className="row row-cols-1">
              <div className="col">
                <div className="breadcrumb__content text-center">
                  <h1 className="breadcrumb__content--title text-white mb-25">
                    Account Page
                  </h1>
                  <ul className="breadcrumb__content--menu d-flex justify-content-center">
                    <li className="breadcrumb__content--menu__items">
                      <Link className="text-white" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="breadcrumb__content--menu__items">
                      <span className="text-white">Account Page</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End breadcrumb section */}
        {/* Start login section */}
        <div className="login__section section--padding sm:mb-10 sm:-mt-10">
          <div className="container">
            <div className="login__section--inner">
              <div className="row row-cols-md-2 row-cols-1">
                <div className="col">
                  <div className="account__login">
                    <div className="account__login--header mb-25">
                      <h2 className="account__login--header__title h3 mb-10">
                        Login
                      </h2>
                      <p className="account__login--header__desc">
                        Login if you are a returning customer.
                      </p>
                    </div>
                    <div className="account__login--inner">
                      <form onSubmit={LoginFormik.handleSubmit}>
                        <input
                          className="account__login--input"
                          placeholder="Email Address"
                          type="email"
                          name="email"
                          value={LoginFormik.values.email}
                          onChange={LoginFormik.handleChange("email")}
                          onBlur={LoginFormik.handleBlur("email")}
                        />
                        <div className="error">
                          {LoginFormik.touched.email && LoginFormik.errors.email}
                        </div>
                        <input
                          className="account__login--input"
                          placeholder="Password"
                          type="password"
                          name="password"
                          value={LoginFormik.values.password}
                          onChange={LoginFormik.handleChange("password")}
                          onBlur={LoginFormik.handleBlur("password")}
                        />
                        <div className="error">
                          {LoginFormik.touched.password && LoginFormik.errors.password}
                        </div>
                        <div className="account__login--remember__forgot mb-15 d-flex justify-content-between align-items-center">
                          <div className="account__login--remember position__relative">
                            <input
                              className="checkout__checkbox--input"
                              id="check1"
                              type="checkbox"
                            />
                            <span className="checkout__checkbox--checkmark" />
                            <label
                              className="checkout__checkbox--label login__remember--label"
                              htmlFor="check1"
                            >
                              Remember me
                            </label>
                          </div>
                          <Link to="/forgot-password" className="account__login--forgot">
                            Forgot Your Password?
                          </Link>
                        </div>
                        <button
                          className="account__login--btn primary__btn"
                          type="submit"
                        >
                          Login
                        </button>
                        <div className="account__login--divide">
                          <span className="account__login--divide__text">
                            OR
                          </span>
                        </div>
                        <div className="account__social d-flex justify-content-center mb-15">
                          <a
                            className="account__social--link facebook"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/"
                          >
                            Facebook
                          </a>
                          <a
                            className="account__social--link google"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.google.com/"
                          >
                            Google
                          </a>
                          <a
                            className="account__social--link twitter"
                            target="_blank"
                            rel="noreferrer"
                            href="https://twitter.com/"
                          >
                            Twitter
                          </a>
                        </div>
                        <p className="account__login--signup__text">
                          Don't Have an Account?{" "}
                          <Link to="/register">
                            <button type="button">Sign up now</button>
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* End login section */}
      </main>
      <Footer />
    </div>
  );
}

export default Login;