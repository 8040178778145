import React from "react";
import "swiper/css/bundle";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
SwiperCore.use([Navigation, Pagination, Autoplay]);

function RelatedProducts() {
  const { product } = useSelector((state) => state.product);
  return (
    <div>
      {" "}
      <section className="product__section product__section--style3 section--padding pt-0">
        <div className="container product3__section--container">
          <div className="section__heading text-center mb-50">
            <h2 className="section__heading--maintitle">Related Products</h2>
          </div>
          <div className="product__section--inner product__swiper--column4__activation swiper">
            <div className="swiper-wrapper">
              <Swiper
                spaceBetween={50}
                slidesPerView={4}
                navigation={{ hidden: true }}
                loop={true}
                breakpoints={{
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 2,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 2,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 2,
                  },
                  // when window width is >= 1024px
                  0: {
                    slidesPerView: 2,
                  },
                  1200: {
                    slidesPerView: 5,
                  },
                }}
              >
                {product.map((product) => (
                  <SwiperSlide key={product._id}>
                    <div className="swiper-slide">
                      <div className="product__items ">
                        <div className="product__items--thumbnail">
                          <Link
                            className="product__items--link"
                            to={`/productdetails/${product._id}`}
                          >
                            <img
                              className="product__items--img product__primary--img"
                              src={
                                product?.colors?.[0]?.images?.[0]?.secure_url
                              }
                              alt="product-img"
                            />
                            <img
                              className="product__items--img product__secondary--img"
                              src={
                                product?.colors?.[0]?.images?.[1]?.secure_url ||
                                product?.colors?.[0]?.images?.[0]?.secure_url
                              }
                              alt="product-img"
                            />
                          </Link>
                          <div className="product__badge">
                            {product.quantity > 0 ? (
                              <span className="product__badge--items sale">
                                OnSale
                              </span>
                            ) : (
                              <span className="product__badge--items sale">
                                StockOut
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="product__items--content">
                          <h3 className="product__items--content__title h4">
                            {product.title}
                          </h3>
                          <div className="product__items--price">
                            <span className="text-md font-bold text-gray-700">
                              {product.price}.00 ETB
                            </span>
                            <span className="price__divided" />
                            <span className="product__items--content__title old__price text-red-600">
                              {product.oldPrice}.00 ETB
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RelatedProducts;
