import React, { Suspense, lazy, useState, useEffect } from "react";
import "./index.css";
import loader from "./asset/wib.png";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { DataProvider } from "./DataContext/DataContext";
import OfflineNotice from "./components/OfflineNotice";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import ProductDetails from "./components/ProductDetails";

const Home = lazy(() => import("./components/Home"));
const MyAccount = lazy(() => import("./components/MyAccount"));
const Wishlist = lazy(() => import("./components/Wishlist"));
const Cart = lazy(() => import("./components/Cart"));
const Woman = lazy(() => import("./components/Woman"));
const Checkout = lazy(() => import("./components/Checkout"));
const Register = lazy(() => import("./components/Signup"));
const About = lazy(() => import("./components/About"));
const Men = lazy(() => import("./components/Men"));
const Kids = lazy(() => import("./components/Kids"));
const Couples = lazy(() => import("./components/Couples"));
const NewIn = lazy(() => import("./components/NewIn"));
const Shop = lazy(() => import("./components/Shop"));
const ProductAll = lazy(() => import("./components/ProductAll"));
const PrivacyAndPolicy = lazy(() => import("./components/PrivacyAndPolicy"));
const Faq = lazy(() => import("./components/Faq"));
const Category = lazy(() => import("./components/Category"));
const Filter = lazy(() => import("./components/Filter"));
const Search = lazy(() => import("./components/Search"));
const Navmobile = lazy(() => import("./components/Navmobile"));
const SuccessPayment = lazy(() => import("./components/SuccessPayment"));
const BestOfTheMonth = lazy(() => import("./components/BestOfTheMonth"));
const Order = lazy(() => import("./components/Order"));
const DirectContact = lazy(() => import("./components/DirectContact"));
function App() {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 5000); // Display the loader for 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <DataProvider>
      <BrowserRouter>
        <Header />
        <OfflineNotice />

        <Suspense
          fallback={
            showLoader && (
              <div className="fixed inset-0 flex justify-center items-center z-50">
                <div className="relative flex justify-center items-center">
                  <div className="absolute animate-spin rounded-full h-36 w-36 border-t-4 border-b-4 border-red-500"></div>
                  <img
                    src={loader}
                    className="rounded-full h-32 w-32"
                    alt="Wub-fashion...."
                  />
                </div>
              </div>
            )
          }
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/myaccount" element={<MyAccount />} />
            <Route
              path="/wishlist"
              element={
                <PrivateRoutes>
                  <Wishlist />
                </PrivateRoutes>
              }
            />
            <Route
              path="/cart"
              element={
                <PrivateRoutes>
                  <Cart />
                </PrivateRoutes>
              }
            />
            <Route path="/women" element={<Woman />} />
            <Route
              path="/checkout"
              element={
                <PrivateRoutes>
                  <Checkout />
                </PrivateRoutes>
              }
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/productdetails/:id" element={<ProductDetails />} />
            <Route path="/register" element={<Register />} />
            <Route path="/about" element={<About />} />
            <Route path="/header" element={<Header />} />
            <Route path="/men" element={<Men />} />
            <Route path="/kid" element={<Kids />} />
            <Route path="/couple" element={<Couples />} />
            <Route path="/new" element={<NewIn />} />
            <Route path="/shop/:id" element={<Shop />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/productall" element={<ProductAll />} />
            <Route path="/privacyandpolicy" element={<PrivacyAndPolicy />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/category" element={<Category />} />
            <Route path="/filter" element={<Filter />} />
            <Route path="/search" element={<Search />} />
            <Route path="/navmobile" element={<Navmobile />} />
            <Route path="/orders" element={<Order />} />
            <Route path="/payment/success" element={<SuccessPayment />} />
            <Route path="/BestOfTheMonth" element={<SuccessPayment />} />
          </Routes>
          <DirectContact/>
        </Suspense>
      </BrowserRouter>
    </DataProvider>
  );
}

export default App;
